(function($){
	"use strict";
	$(document).on('click', function(e){
		var outside_space = $(".outside");
		if(!outside_space.is(e.target) &&
			outside_space.has(e.target).length === 0){
			$(".menu-to-be-close").removeClass("d-block");
			$('.menu-to-be-close').css('display', 'none');
		}
	})

	$('.prooduct-details-box .close').on('click', function(e){
		var tets = $(this).parent().parent().parent().parent().addClass('d-none');
		console.log(tets);
	})


	if($('.page-wrapper').hasClass('horizontal-wrapper')){

		$(".sidebar-list").hover(
			function(){
				$(this).addClass("hoverd");
			},
			function(){
				$(this).removeClass("hoverd");
			}
		);
		$(window).on('scroll', function(){
			if($(this).scrollTop() < 600){
				$(".sidebar-list").removeClass("hoverd");
			}
		});
	}

	/*----------------------------------------
	 passward show hide
	 ----------------------------------------*/
	$('.show-hide').show();
	$('.show-hide span').addClass('show');

	$('.show-hide span').click(function(){
		if($(this).hasClass('show')){
			$('input[name="login[password]"]').attr('type', 'text');
			$(this).removeClass('show');
		} else{
			$('input[name="login[password]"]').attr('type', 'password');
			$(this).addClass('show');
		}
	});
	$('form button[type="submit"]').on('click', function(){
		$('.show-hide span').addClass('show');
		$('.show-hide').parent().find('input[name="login[password]"]').attr('type', 'password');
	});

	/*=====================
	  02. Background Image js
	  ==========================*/
	$(".bg-center").parent().addClass('b-center');
	$(".bg-img-cover").parent().addClass('bg-size');
	$('.bg-img-cover').each(function(){
		var el = $(this),
			src = el.attr('src'),
			parent = el.parent();
		parent.css({
			'background-image': 'url(' + src + ')',
			'background-size': 'cover',
			'background-position': 'center',
			'display': 'block'
		});
		el.hide();
	});

	$(".mega-menu-container").css("display", "none");
	$(".header-search").click(function(){
		$(".search-full").addClass("open");
	});
	$(".close-search").click(function(){
		$(".search-full").removeClass("open");
		$("body").removeClass("offcanvas");
	});
	$(".mobile-toggle").click(function(){
		$(".nav-menus").toggleClass("open");
	});
	$(".mobile-toggle-left").click(function(){
		$(".left-header").toggleClass("open");
	});
	$(".bookmark-search").click(function(){
		$(".form-control-search").toggleClass("open");
	})
	$(".filter-toggle").click(function(){
		$(".product-sidebar").toggleClass("open");
	});
	$(".toggle-data").click(function(){
		$(".product-wrapper").toggleClass("sidebaron");
	});
	$(".form-control-search input").keyup(function(e){
		if(e.target.value){
			$(".page-wrapper").addClass("offcanvas-bookmark");
		} else{
			$(".page-wrapper").removeClass("offcanvas-bookmark");
		}
	});
	$(".search-full input").keyup(function(e){
		console.log(e.target.value);
		if(e.target.value){
			$("body").addClass("offcanvas");
		} else{
			$("body").removeClass("offcanvas");
		}
	});

	$('body').keydown(function(e){
		if(e.keyCode == 27){
			$('.search-full input').val('');
			$('.form-control-search input').val('');
			$('.page-wrapper').removeClass('offcanvas-bookmark');
			$('.search-full').removeClass('open');
			$('.search-form .form-control-search').removeClass('open');
			$("body").removeClass("offcanvas");
		}
	});
	$(".mode").on("click", function(){
		$('.mode i').toggleClass("fa-moon-o").toggleClass("fa-lightbulb-o");
		$('body').toggleClass("dark-only");
		var color = $(this).attr("data-attr");
		localStorage.setItem('body', 'dark-only');
	});

})(jQuery);

$('.loader-wrapper').fadeOut('slow', function(){
	$(this).remove();
});

$(window).on('scroll', function(){
	if($(this).scrollTop() > 600){
		$('.tap-top').fadeIn();
	} else{
		$('.tap-top').fadeOut();
	}
});


$('.tap-top').click(function(){
	$("html, body").animate({
		scrollTop: 0
	}, 600);
	return false;
});

function toggleFullScreen(){
	if((document.fullScreenElement && document.fullScreenElement !== null) ||
		(!document.mozFullScreen && !document.webkitIsFullScreen)){
		if(document.documentElement.requestFullScreen){
			document.documentElement.requestFullScreen();
		} else if(document.documentElement.mozRequestFullScreen){
			document.documentElement.mozRequestFullScreen();
		} else if(document.documentElement.webkitRequestFullScreen){
			document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
		}
	} else{
		if(document.cancelFullScreen){
			document.cancelFullScreen();
		} else if(document.mozCancelFullScreen){
			document.mozCancelFullScreen();
		} else if(document.webkitCancelFullScreen){
			document.webkitCancelFullScreen();
		}
	}
}
(function($, window, document, undefined){
	"use strict";
	var $ripple = $(".js-ripple");
	$ripple.on("click.ui.ripple", function(e){
		var $this = $(this);
		var $offset = $this.parent().offset();
		var $circle = $this.find(".c-ripple__circle");
		var x = e.pageX - $offset.left;
		var y = e.pageY - $offset.top;
		$circle.css({
			top: y + "px",
			left: x + "px"
		});
		$this.addClass("is-active");
	});
	$ripple.on(
		"animationend webkitAnimationEnd oanimationend MSAnimationEnd",
		function(e){
			$(this).removeClass("is-active");
		});


})(jQuery, window, document);


// active link

$(".chat-menu-icons .toogle-bar").click(function(){
	$(".chat-menu").toggleClass("show");
});

function format_divisa(){
	$('.with-divisa').each(function(){
		var item = $(this).html();
		item = item.replace("€","<span class='style_valuta'>€</span>");
		$(this).html(item);
	})
}

// Language
var tnum = 'en';

$(document).ready(function(){

	if(localStorage.getItem("primary") != null){
		var primary_val = localStorage.getItem("primary");
		$("#ColorPicker1").val(primary_val);
		var secondary_val = localStorage.getItem("secondary");
		$("#ColorPicker2").val(secondary_val);
	}


	$(document).click(function(e){
		$('.translate_wrapper, .more_lang').removeClass('active');
	});
	$('.translate_wrapper .current_lang').click(function(e){
		e.stopPropagation();
		$(this).parent().toggleClass('active');

		setTimeout(function(){
			$('.more_lang').toggleClass('active');
		}, 5);
	});
	if($( window ).width() < 576){
		$( ".page-body" ).css("margin-top","80px !important");
	}
	format_divisa();


	/*TRANSLATE*/
	translate(tnum);

	$('.more_lang .lang').click(function(){
		$(this).addClass('selected').siblings().removeClass('selected');
		$('.more_lang').removeClass('active');

		var i = $(this).find('i').attr('class');
		var lang = $(this).attr('data-value');
		var tnum = lang;
		translate(tnum);

		$('.current_lang .lang-txt').text(lang);
		$('.current_lang i').attr('class', i);


	});
});

function translate(tnum){
	$('.lan-1').text(trans[0][tnum]);
	$('.lan-2').text(trans[1][tnum]);
	$('.lan-3').text(trans[2][tnum]);
	$('.lan-4').text(trans[3][tnum]);
	$('.lan-5').text(trans[4][tnum]);
	$('.lan-6').text(trans[5][tnum]);
	$('.lan-7').text(trans[6][tnum]);
	$('.lan-8').text(trans[7][tnum]);
	$('.lan-9').text(trans[8][tnum]);
}

var trans = [{
	en: 'General',
	pt: 'Geral',
	es: 'Generalo',
	fr: 'GÃ©nÃ©rale',
	de: 'Generel',
	cn: 'ä¸€èˆ¬',
	ae: 'Ø­Ø¬Ù†Ø±Ø§Ù„ Ù„ÙˆØ§Ø¡'
}, {
	en: 'Dashboards,widgets & layout.',
	pt: 'PainÃ©is, widgets e layout.',
	es: 'Paneloj, fenestraÄµoj kaj aranÄo.',
	fr: "Tableaux de bord, widgets et mise en page.",
	de: 'Dashboards, widgets en lay-out.',
	cn: 'ä»ªè¡¨æ¿ï¼Œå°å·¥å…·å’Œå¸ƒå±€ã€‚',
	ae: 'Ù„ÙˆØ­Ø§Øª Ø§Ù„Ù…Ø¹Ù„ÙˆÙ…Ø§Øª ÙˆØ§Ù„Ø£Ø¯ÙˆØ§Øª ÙˆØ§Ù„ØªØ®Ø·ÙŠØ·.'
}, {
	en: 'Dashboards',
	pt: 'PainÃ©is',
	es: 'Paneloj',
	fr: 'Tableaux',
	de: 'Dashboards',
	cn: ' ä»ªè¡¨æ¿ ',
	ae: 'ÙˆØ­Ø§Øª Ø§Ù„Ù‚ÙŠØ§Ø¯Ø© '
}, {
	en: 'Default',
	pt: 'PadrÃ£o',
	es: 'Vaikimisi',
	fr: 'DÃ©faut',
	de: 'Standaard',
	cn: 'é›»å­å•†å‹™',
	ae: 'ÙˆØ¥ÙØªØ±Ø§Ø¶ÙŠ'
}, {
	en: 'Ecommerce',
	pt: 'ComÃ©rcio eletrÃ´nico',
	es: 'Komerco',
	fr: 'Commerce Ã©lectronique',
	de: 'E-commerce',
	cn: 'é›»å­å•†å‹™',
	ae: 'ÙˆØ§Ù„ØªØ¬Ø§Ø±Ø© Ø§Ù„Ø¥Ù„ÙƒØªØ±ÙˆÙ†ÙŠØ©'
}, {
	en: 'Widgets',
	pt: 'Ferramenta',
	es: 'Vidin',
	fr: 'Widgets',
	de: 'Widgets',
	cn: 'å°éƒ¨ä»¶',
	ae: 'ÙˆØ§Ù„Ø­Ø§Ø¬ÙŠØ§Øª'
}, {
	en: 'Page layout',
	pt: 'Layout da pÃ¡gina',
	es: 'PaÄa aranÄo',
	fr: 'Tableaux',
	de: 'Mise en page',
	cn: 'é é¢ä½ˆå±€',
	ae: 'ÙˆØªØ®Ø·ÙŠØ· Ø§Ù„ØµÙØ­Ø©'
}, {
	en: 'Applications',
	pt: 'FormulÃ¡rios',
	es: 'Aplikoj',
	fr: 'Applications',
	de: 'Toepassingen',
	cn: 'æ‡‰ç”¨é ˜åŸŸ',
	ae: 'ÙˆØ§Ù„ØªØ·Ø¨ÙŠÙ‚Ø§Øª'
}, {
	en: 'Ready to use Apps',
	pt: 'Pronto para usar aplicativos',
	es: 'Preta uzi Apps',
	fr: ' Applications prÃªtes Ã  lemploi ',
	de: 'Klaar om apps te gebruiken',
	cn: 'ä»ªè¡¨æ¿',
	ae: 'Ø¬Ø§Ù‡Ø² Ù„Ø§Ø³ØªØ®Ø¯Ø§Ù… Ø§Ù„ØªØ·Ø¨ÙŠÙ‚Ø§Øª'
},

];

$(".mobile-title svg").click(function(){
	$(".header-mega").toggleClass("d-block");
});

$(".onhover-dropdown").on("click", function(){
	$(this).children('.onhover-show-div').toggleClass("active");
});

$("#flip-btn").click(function(){
	$(".flip-card-inner").addClass("flipped")
});

$("#flip-back").click(function(){
	$(".flip-card-inner").removeClass("flipped")
})

function loader_animated(visibility){
	if(visibility == 'hide'){
		$("#loader-overlay").hide();
	} else{
		$("#loader-overlay").show();
	}
}

function syntax_zipcode(){
	var regExp = /^[0-9]{5}$/;
	var CAP = $("#CAP").val();

	if(!regExp.test(CAP)){
		$("#CAP").addClass("is-invalid");
		$("#CAP").css("border-color","#dc3545");
		$("#CAP").addClass("input-invalid");

		$('html, body').animate({
			scrollTop: $('#CAP').offset().top - (200)
		}, 1000);

		return false;
	} else{
		$("#CAP").removeClass("is-invalid");
		$("#CAP").css("border-color","#ced4da");
		$("#CAP").removeClass("input-invalid");
		return true;
	}

}

function syntax_country(){
	var regExp = /^[a-zA-Z]{2}$/;
	var Provincia = $("#Provincia").val();
	//Provincia = Provincia.toUpperCase();
	//$("#Provincia").val(Provincia);

	if(!regExp.test(Provincia)){
		$("#Provincia").addClass("is-invalid");
		$("#Provincia").css("border-color","#dc3545");
		$("#Provincia").addClass("input-invalid");

		$('html, body').animate({
			scrollTop: $('#Provincia').offset().top - (200)
		}, 1000);

		return false;
	} else{
		$("#Provincia").removeClass("is-invalid");
		$("#Provincia").removeClass("is-invalid");
		$("#Provincia").css("border-color","#ced4da");
		$("#Provincia").removeClass("input-invalid");
		return true;
	}
}

function syntax_fiscal_code(){
	if($("#Codice_fiscale").val().length !== 16){
		$("#Codice_fiscale").addClass("is-invalid");
		$("#Codice_fiscale").css("border-color","#dc3545");
		$("#Codice_fiscale").addClass("input-invalid");

		$('html, body').animate({
			scrollTop: $('#Codice_fiscale').offset().top - (200)
		}, 1000);

		return false;
	} else{
		$("#Codice_fiscale").removeClass("is-invalid");
		$("#Codice_fiscale").removeClass("is-invalid");
		$("#Codice_fiscale").css("border-color","#ced4da");
		$("#Codice_fiscale").removeClass("input-invalid");
		return true;
	}
}

function syntax_cap(){
	if($("#CAP").val().length < 5){
		$("#CAP").addClass("is-invalid");
		$("#CAP").css("border-color","#dc3545");
		$("#CAP").addClass("input-invalid");

		$('html, body').animate({
			scrollTop: $('#CAP').offset().top - (200)
		}, 1000);

		return false;
	} else{
		$("#CAP").removeClass("is-invalid");
		$("#CAP").removeClass("is-invalid");
		$("#CAP").css("border-color","#ced4da");
		$("#CAP").removeClass("input-invalid");
		return true;
	}
}


function invalid_field(id_element){
	if(document.getElementById(id_element)){
		$("#" + id_element).addClass('is-invalid');
		$("#" + id_element).css("border-color","#dc3545");
		$("#" + id_element).addClass("input-invalid");
	}
}
function valid_field(id_element){
	if(document.getElementById(id_element)){
		$("#" + id_element).removeClass('is-invalid');
		$("#" + id_element).css("border-color", "#ced4da");
		$("#" + id_element).removeClass("input-invalid");
	}
}
function translate_month_news(month, lang){
	if(lang == 'it'){
		if(month == 'Jan'){month = 'Gen';}
		if(month == 'Feb'){month = 'Feb';}
		if(month == 'Mar'){month = 'Mar';}
		if(month == 'Apr'){month = 'Apr';}
		if(month == 'May'){month = 'Mag';}
		if(month == 'Jun'){month = 'Giu';}
		if(month == 'Jul'){month = 'Lug';}
		if(month == 'Aug'){month = 'Ago';}
		if(month == 'Sep'){month = 'Set';}
		if(month == 'Oct'){month = 'Ott';}
		if(month == 'Nov'){month = 'Nov';}
		if(month == 'Dec'){month = 'Dic';}
	}
	return month;
}
